import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { useParams, useNavigate } from "react-router-dom";

export default function DropdownSearchMenu({ options }) {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      navigate(highlightedRow, params);
    }
  }

  return (
    <Dropdown
      placeholder="Switch to:"
      search
      selection
      options={options}
      onChange={(e, d) => {
        setHighlightedRow(d.value);
      }}
      onKeyDown={(e) => handleKeyDown(e)}
      closeOnChange={true}
    />
  );
}
