import React, { useEffect } from "react";
import { useMap } from "@vis.gl/react-google-maps";

export default function MapBoundary({ polygons, deletedPolygon }) {
  const map = useMap();

  useEffect(() => {
    deletedPolygon?.polygonDetails?.setMap(null);
  }, [deletedPolygon]);

  function renderPolygons() {
    for (let polygon of polygons) {
      polygon.polygonDetails.setMap(map);
    }
  }

  return <div>{renderPolygons()}</div>;
}
