import React, { useContext } from "react";
import { Container, Header, CardHeader, CardDescription, CardContent, Card } from "semantic-ui-react";
import { UserContext } from "./UserContext";
import { Link } from "react-router-dom";
import { canAccess, hasConstituencies } from "../logic/userPermissionsHandler";

export default function FrontPage() {
  const user = useContext(UserContext);

  return (
    <Container>
      <Header as="h2">Dashboard</Header>
      {!user.isLoaded ? (
        <>Loading user details..</>
      ) : user.isValid ? (
        <>
          {hasConstituencies(user) && canAccess(user, "basicCanvassing") ? (
            <Card>
              <CardContent>
                <CardHeader>
                  <Link to="/seatData">Seat Data</Link>
                </CardHeader>

                <CardDescription>Data from constituencies</CardDescription>
              </CardContent>
            </Card>
          ) : (
            <></>
          )}
          {canAccess(user, "connectMetadata") ? (
            <Card>
              <CardContent>
                <CardHeader>
                  <Link to="/metaData">Metadata</Link>
                </CardHeader>

                <CardDescription>Data about Connect</CardDescription>
              </CardContent>
            </Card>
          ) : (
            <></>
          )}

          {canAccess(user, "nationalStats") ? (
            <Card>
              <CardContent>
                <CardHeader>
                  <Link to="/nationalData">National Data</Link>
                </CardHeader>

                <CardDescription>National campaign stats</CardDescription>
              </CardContent>
            </Card>
          ) : (
            <></>
          )}

          {canAccess(user, "manageUsers") ? (
            <Card>
              <CardContent>
                <CardHeader>
                  <Link to="/users">Users</Link>
                </CardHeader>

                <CardDescription>Manage dashboard users</CardDescription>
              </CardContent>
            </Card>
          ) : (
            <></>
          )}

          {canAccess(user, "privateCharlie") ? (
            <Card>
              <CardContent>
                <CardHeader>
                  <Link to="/committeeData">Committee Data</Link>
                </CardHeader>

                <CardDescription>Data from committees</CardDescription>
              </CardContent>
            </Card>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>Unknown user</>
      )}
    </Container>
  );
}
