import React, { useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import api from "../logic/apiHandler";
import { generatePath, Link, useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import Dimmer from "./Dimmer";
import { Helmet } from "react-helmet-async";

export default function ParentVoteHistory() {
  const { areaID } = useParams();
  const [voteHistoryData, setVoteHistoryData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      setDataLoaded(false);
      setError(null);

      try {
        setVoteHistoryData(await api.seatData.parentVoteHistorySummary(areaID));
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    })();
  }, [areaID]);

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      cell: ({ row }) => (
        <Link to={generatePath(`/seatData/${row.original.code}/voteHistory`)}>{row.original.name}</Link>
      ),
    },
    {
      header: "PD Count",
      accessorKey: "pdCount",
    },
    {
      header: "Local Electorate",
      accessorKey: "localElectorate",
    },
    {
      header: "West Electorate",
      accessorKey: "westElectorate",
    },
    {
      header: "W2017pd",
      accessorKey: "W2017pd",
      cell: ({ row }) => getIndicator(row.original.W2017pd, row.original.pdCount),
    },
    {
      header: "W2019pd",
      accessorKey: "W2019pd",
      cell: ({ row }) => getIndicator(row.original.W2019pd, row.original.pdCount),
    },
    {
      header: "W2024pd",
      accessorKey: "W2024pd",
      cell: ({ row }) => getIndicator(row.original.W2024pd, row.original.pdCount),
    },
    {
      header: "L2019pd",
      accessorKey: "L2019pd",
      cell: ({ row }) => getIndicator(row.original.L2019pd, row.original.pdCount),
    },
    {
      header: "L2021pd",
      accessorKey: "L2021pd",
      cell: ({ row }) => getIndicator(row.original.L2021pd, row.original.pdCount),
    },
    {
      header: "L2022pd",
      accessorKey: "L2022pd",
      cell: ({ row }) => getIndicator(row.original.L2022pd, row.original.pdCount),
    },
    {
      header: "L2023pd",
      accessorKey: "L2023pd",
      cell: ({ row }) => getIndicator(row.original.L2023pd, row.original.pdCount),
    },
    {
      header: "L2024pd",
      accessorKey: "L2024pd",
      cell: ({ row }) => getIndicator(row.original.L2024pd, row.original.pdCount),
    },
    {
      header: "D2016pd",
      accessorKey: "D2016pd",
      cell: ({ row }) => getIndicator(row.original.D2016pd, row.original.pdCount),
    },
    {
      header: "D2021pd",
      accessorKey: "D2021pd",
      cell: ({ row }) => getIndicator(row.original.D2021pd, row.original.pdCount),
    },
    {
      header: "W2017",
      accessorKey: "W2017",
    },
    {
      header: "W2019",
      accessorKey: "W2019",
    },
    {
      header: "W2024",
      accessorKey: "W2024",
    },
    {
      header: "L2019",
      accessorKey: "L2019",
    },
    {
      header: "L2021",
      accessorKey: "L2021",
    },
    {
      header: "L2022",
      accessorKey: "L2022",
    },
    {
      header: "L2023",
      accessorKey: "L2023",
    },
    {
      header: "L2024",
      accessorKey: "L2024",
    },
    {
      header: "D2016",
      accessorKey: "D2016",
    },
    {
      header: "D2021",
      accessorKey: "D2021",
    },
  ];

  function getIndicator(value, pdCount) {
    if (value === 0) {
      return "-";
    } else if (value === pdCount) {
      return (
        <div>
          {value} <Icon name="check" color="green" size="small" />
        </div>
      );
    } else if (value < pdCount) {
      return (
        <div>
          {value}{" "}
          <Icon
            name="battery half"
            size="small"
            style={{
              color: "#FAA61A",
            }}
          />
        </div>
      );
    }
    return value;
  }

  const table = useReactTable({
    columns,
    data: voteHistoryData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>Activist Codes</title>
      </Helmet>
      <Dimmer dataLoaded={dataLoaded} error={error}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()}>
                      {
                        <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <Icon name="caret up" color="yellow" />,
                            desc: <Icon name="caret down" color="yellow" />,
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      }
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    /*              if (cell.id.split("_")[1] !== "pdCount" && cell.id.includes("pd")) {
                      const pdCount = row.getValue("pdCount");
                      const cellValue = cell.getValue();
                      if (cellValue === 0) {
                        return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                      } else if (cellValue < pdCount) {
                        return (
                          <td key={cell.id} style={{ backgroundColor: "#FAA61A" }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      } else if (cellValue === pdCount) {
                        return (
                          <td key={cell.id} style={{ backgroundColor: "green" }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      }
                    }*/
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Dimmer>
    </div>
  );
}
